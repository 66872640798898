table.thaco p {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
}

table.thaco {
    border: 0.5px solid #454545;
    border-collapse: collapse;
    font-size: 0.8rem;
    margin-bottom: 6rem;
}

table.thaco .header {
    background-color: #FFFFCC;
    color: #003399 !important;
}

table.thaco th {
    border: 0.5px solid #454545;
}

table.thaco th.pb-name {
    text-align: left;
}

table.thaco td {
    border: 0.5px solid #454545;
    font-size: 1.5em;
    text-align: right;
}

table.thaco td.alert {
    color: red;
    text-align: center;
}

table.thaco .h-row {
    padding: 0.3rem 0.5rem;
}

table.thaco .data {
    text-align: center;
    color: #003399 !important;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 14px;
    font-weight: bold;
}
span {
    font-size: 19px;
    font-family: ui-rounded;
    margin-left: 10px;
    padding-right: 5px;
    font-weight: 500;
}
input[type="date"], select {
    font-size: 19px;
    font-family: ui-rounded;
    padding: 2px 2px 2px 4px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 200;
    font-size: 1rem;
}
