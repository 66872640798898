table.thaco {
    font-family: 'Times New Roman', Times, serif;
}

table.thaco thead {
    position: sticky;
    top: 0;
    background-color: #0F9ED5;
    color: white;
    z-index: 100;
}

table.thaco td.code {
    text-align: right;
}

table.thaco button {
    border: none;
    background: transparent;
}

.table-wrap {
    width: 100vw;
    overflow-x: auto;
    height: 'calc(100vh - 70px - 64px)';
    overflow-y: 'scroll';
}

table.thaco th, table.thaco td {
    padding: 0.2rem auto;
}

table.thaco tbody th {
    white-space: nowrap;
}

thead .stick-column {
    /*z-index: 1000;*/
    background-color: #0F9ED5;
}

tbody .stick-column {
    /*z-index: 800;*/
}

.stick-column {
    position: sticky;
    background-color: inherit;
}